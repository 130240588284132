<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex flex-column px-4 pt-4 pb-2">
            <h3 class="font-weight-bold mb-4" style='font-size: 30px'>Nuevo Artículo</h3>
            <h4 class='mb-2' style='font-size: large;'>General</h4>
            <p class='w-100' style='font-size: 13px;'>Seleccione un categoría para que sus informes sean más
              detallados. La descripción se llenara cuando el articulo sea seleccionado en una factura o cuenta.</p>
            <hr style='height: 1px; background: var(--dark);' class='w-100' />
          </div>
          <form class='px-4 pt-4' @submit.prevent="saveCategoria" style='height: auto;'>
            <label style='font-size: medium;'>Tipo *</label>
            <div class='d-flex' style='gap: 3px;'>
              <div>
                <vs-button class="px-1 px-md-5" size="large" v-if="selectedButton === 'producto'"
                  @click.prevent="selectButton('producto')">Producto</vs-button>
                <vs-button v-else class="px-1 px-md-5" size="large" dark shadow
                  @click.prevent="selectButton('producto')">Producto</vs-button>
              </div>
              <div>
                <vs-button class="px-1 px-md-5" size="large" v-if="selectedButton === 'servicio'"
                  @click.prevent="selectButton('servicio')">Servicio</vs-button>
                <vs-button class="px-1 px-md-5" size="large" dark shadow v-else
                  @click.prevent="selectButton('servicio')">Servicio</vs-button>
              </div>
            </div>
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-6 pb-3 pb-md-0'>
                <label for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name='Nombre' aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Nombre" trim required></b-form-input>
              </div>
              <div class="col-12 col-md-6">
                <label for='Categoria'>Categoría</label><br />
                <b-form-select name='Categoria' placeholder='-- Please select an option --' :options="listaCategoria"
                  required value='null'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>

                </b-form-select>
              </div>
            </div>
            <div class='pt-3'>
              <label for="Descripcion">Descripción</label><br />
              <b-form-textarea rows="6" class='p-3' name='Descripcion' placeholder="Ingrese Descripción"
                required></b-form-textarea>
            </div>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Facturación</h4>
              <p class='w-100' style='font-size: 13px;'>La información de venta se utiliza dentro de las facturas, y
                la información de compra se utiliza dentro de las facturas. Los impuestos se aplicarán tanto a las
                facturas como a las cuentas.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100' />
            </div>
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-6'>
                <b-form-checkbox id="checkbox-1" size="lg" v-model="statusVenta" name="checkbox-1" value="accepted"
                  unchecked-value="not_accepted" @change="handleCheckboxChange('venta')">
                  Información de venta
                </b-form-checkbox>
                <div class='pt-2 pb-4'>
                  <label for="PrecioVenta">Precio de Venta *</label>
                  <b-form-input name='PrecioVenta' :disabled="statusVenta !== 'accepted'" id="input-live"
                    aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese el Precio de Venta" trim
                    required type='number'></b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <b-form-checkbox id="checkbox-2" size="lg" v-model="statusCompra" name="checkbox-2" value="accepted"
                  unchecked-value="not_accepted" @change="handleCheckboxChange('compra')">
                  Información de compra
                </b-form-checkbox>
                <div class='pt-2 pb-4'>
                  <label for="PrecioCompra">Precio de Compra *</label><br />
                  <b-form-input type='number' :disabled="statusCompra !== 'accepted'" id="input-live"
                    aria-describedby="input-live-help input-live-feedback" name='PrecioCompra'
                    placeholder="Ingrese el Precio de Compra" trim required></b-form-input>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class="col-12 col-md-6">
                <label for="Sku">Sku</label><br />
                <b-form-input id="Sku" name='Sku' aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Sku" trim required></b-form-input>
              </div>
              <div class="col-12 col-md-6">
                <label for="Stock">Stock</label><br />
                <b-form-input type='number' id="Stock" name='Stock'
                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Stock" trim
                  required></b-form-input>
              </div>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" dark shadow @click.prevent="$router.push('Articulos')">Cancelar</vs-button>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { core } from "../../../../../config/pluginInit";
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        statusVenta: 'accepted',
        statusCompra: 'accepted',
        selectedButton: 'producto',
        waiting: false,
        saveArticuloObjet: {
          Articulo: {
            Nombre: null,
            Tipo: null,
            Sku: null,
            Descripcion: null,
            PrecioVenta: 0,
            PrecioCompra: 0,
            Stock: 0,
          },
          EmpresaId: null,
          CategoriaId: null,
        },
        listaCategoria: [],
      }
    },
    async mounted() {
      const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
      if (valor) {
        this.saveArticuloObjet.EmpresaId = valor.empresa.id;
      }

      core.index();
      await this.getData();
    },
    methods: {
      selectButton(button) {
        this.selectedButton = button;
      },
      handleCheckboxChange(checkbox) {
        if (this.statusVenta !== 'accepted' && this.statusCompra !== 'accepted') {
          if (checkbox === 'venta') {
            this.statusCompra = 'accepted';
          } else if (checkbox === 'compra') {
            this.statusVenta = 'accepted';
          }
        }
      },
      async saveCategoria(event) {
        const data = Object.fromEntries(new FormData(event.target).entries());

        this.saveArticuloObjet.Articulo.Nombre = data.Nombre;
        this.saveArticuloObjet.Articulo.Descripcion = data.Descripcion;
        this.saveArticuloObjet.Articulo.PrecioVenta = Number(data.PrecioVenta) || 0;
        this.saveArticuloObjet.Articulo.PrecioCompra = Number(data.PrecioCompra) || 0;
        this.saveArticuloObjet.Articulo.Stock = Number(data.Stock);
        this.saveArticuloObjet.Articulo.Sku = data.Sku;
        this.saveArticuloObjet.CategoriaId = data.Categoria;
        this.saveArticuloObjet.Articulo.Tipo = this.selectedButton;

        try {
          this.waiting = true;
          const response = await this.$store.getters.fetchPost({
            path: `CONTArticulo/CrearArticulo`,
            data: this.saveArticuloObjet
          })
          if (response.ok) {
            Swal.fire({
              title: 'Artiuclo creado',
              text: 'Se ha guardado el Articulo',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.$router.push('Articulos');
            })
          } else {
            Swal.fire({
              title: 'Error',
              text: 'No se ha guardado el Articulo',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            })
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'No se ha guardado el Articulo',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          })
        } finally {
          this.waiting = false;
        }

      },
      async getData() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: 'CONTCategoria/GetCategoriaByEmpresaId/' + this.saveArticuloObjet.EmpresaId,
          });

          if (res.data.length > 0) {
            this.listaCategoria = res.data.map(item => ({
              value: item.Id,
              text: item.Nombre
            }));
          } else {
            this.saveCategoria = [];
          }

        } catch (error) {
          console.error("Error al obtener la lista de categorias:", error);
          this.saveCategoria = [];
        }
      },
    },
  }
</script>

<style>
  .vs-select__input {
    background-color: transparent !important;
    border: 1px solid var(--vs-colors--dark) !important;
    width: 100%;
  }

  .vs-select-content {
    max-width: 50%;
  }

  .vs-input {
    width: 100%;
    border-radius: 7px;
  }
</style>